import React from 'react';
import { Helmet } from 'react-helmet';

function SocialCardComponent({ title, description, image, url }) {
  return (
    <div>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content="@nekozyarasisan" />
        <meta name="twitter:creator" content="@nekozyarasisan" />
      </Helmet>
    </div>
  );
}

export default SocialCardComponent;