import React, { useState } from 'react';
import { Box, Button, Typography, Card, CardContent, IconButton, Tooltip } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';
import InfoIcon from '@mui/icons-material/Info';
import '@fontsource/noto-serif-jp';

const Home = () => {
  const navigate = useNavigate();
  const [showRules, setShowRules] = useState(false);

  const handleCreateHaiku = () => {
    navigate('/haiku');
  };

  const toggleRules = () => {
    setShowRules(!showRules);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: 'url("back2.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
      onClick={() => showRules && setShowRules(false)}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          display: 'flex',
          gap: '1rem',
        }}
      >
        <Tooltip title="開発者Twitter" arrow>
          <IconButton
            aria-label="開発者Twitter"
            onClick={() => window.open('https://twitter.com/@nekozyarasisan', '_blank')}
            sx={{
              backgroundColor: '#1DA1F2',
              color: '#fff',
              '&:hover': { backgroundColor: '#1991DA' },
            }}
          >
            <TwitterIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          aria-label="ルール"
          onClick={toggleRules}
          sx={{
            backgroundColor: '#4a4a4a',
            color: '#fff',
            '&:hover': { backgroundColor: '#333' },
          }}
        >
          <InfoIcon />
        </IconButton>
      </Box>

      <Card
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.85)',
          borderRadius: '15px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
          overflow: 'hidden',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '3rem !important',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, ease: "easeOut" }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontFamily: '"Yuji Boku", serif',
                fontWeight: 700,
                color: '#333',
                textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                writingMode: 'vertical-rl',
                textOrientation: 'upright',
                letterSpacing: '0.5rem',
                lineHeight: 1.5,
                marginBottom: '2rem',
              }}
            >
              トレンド俳句
            </Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Button
              variant="contained"
              onClick={handleCreateHaiku}
              sx={{
                fontFamily: '"Yuji Boku", serif',
                backgroundColor: '#4a4a4a',
                color: '#fff',
                padding: '12px 24px',
                fontSize: '1.1rem',
                '&:hover': {
                  backgroundColor: '#333',
                },
              }}
            >
              俳句を詠む
            </Button>
          </motion.div>
        </CardContent>
      </Card>

      <AnimatePresence>
        {showRules && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              padding: '2rem',
              borderRadius: '15px',
              maxWidth: '80%',
              maxHeight: '80%',
              overflow: 'auto',
            }}
          >
            <Typography variant="h5" sx={{ fontFamily: '"Yuji Boku", serif', marginBottom: '1rem' }}>
              ルール
            </Typography>
            <Typography sx={{ fontFamily: '"Yuji Boku", serif' }}>
              1. トレンドワードを使って俳句を作成しよう！<br />
              2. トレンドワードは最低一個含めてね！<br />
              3. マナーを守って自由に作ろう！<br />
              4. 出来たら画像保存か共有してみよう!<br/>
              *オススメは保存した画像を共有する方法だよ！
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Home;