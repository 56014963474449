import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Card, CardContent, TextField, Button, Chip, Snackbar, Alert } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';
import '@fontsource/noto-serif-jp';

const Haiku = () => {
  const [trends, setTrends] = useState([]);
  const [haiku, setHaiku] = useState(['', '', '']);
  const [fontSize, setFontSize] = useState(24);
  const [selectedTrend, setSelectedTrend] = useState('');
  const [error, setError] = useState('');
  const haikuRef = useRef(null);

  useEffect(() => {
    fetchTrends();
  }, []);

  useEffect(() => {
    fetchTrends();
  }, []);

  const fetchTrends = async () => {
    try {
      const response = await fetch('https://haiku-2yeq.onrender.com/haiku/api/trends/');
      if (response.ok) {
        const data = await response.json();
        setTrends(data);
      } else {
        console.error('Failed to fetch trends');
      }
    } catch (error) {
      console.error('Error fetching trends:', error);
    }
  };

  const handleHaikuChange = (index, value) => {
    const maxLength = index === 1 ? 14 : 10;
    if (value.length <= maxLength) {
      const newHaiku = [...haiku];
      newHaiku[index] = value;
      setHaiku(newHaiku);
    }
  };

  const handleTrendClick = (trend) => {
    if (selectedTrend === trend) {
      setSelectedTrend('');
    } else {
      setSelectedTrend(trend);
      const emptyIndex = haiku.findIndex(line => line.length === 0);
      if (emptyIndex !== -1) {
        handleHaikuChange(emptyIndex, trend);
      }
    }
  };

  const adjustFontSize = useCallback(() => {
    if (haikuRef.current) {
      const containerHeight = haikuRef.current.offsetHeight;
      const textHeight = haikuRef.current.scrollHeight;
      if (textHeight > containerHeight) {
        setFontSize((prevSize) => prevSize - 1);
      } else if (textHeight < containerHeight * 0.9 && fontSize < 24) {
        setFontSize((prevSize) => prevSize + 1);
      }
    }
  }, [fontSize]);

  useEffect(() => {
    adjustFontSize();
  }, [haiku, adjustFontSize]);

  const checkTrendInHaiku = () => {
    if (!selectedTrend) {
      setError('トレンドを使ってね');
      return false;
    }
    const trendInHaiku = haiku.some(line => line.includes(selectedTrend));
    if (!trendInHaiku) {
      setError('選んだトレンドを俳句に入れてね');
      return false;
    }
    return true;
  };

  const saveAsImage = () => {
    if (checkTrendInHaiku()) {
      html2canvas(haikuRef.current).then((canvas) => {
        canvas.toBlob((blob) => {
          FileSaver.saveAs(blob, 'trend-haiku.png');
        });
      });
    }
  };

  const shareHaiku = () => {
    if (checkTrendInHaiku()) {
      const haikuText = haiku.map(line => line.split('').join('\n')).join('\n\n');
      const shareText = encodeURIComponent(`${haikuText}\n\n#トレンド俳句`);
      window.open(`https://twitter.com/intent/tweet?text=${shareText}`, '_blank');
    }
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError('');
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: 'url("back2.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
      }}
    >
      <Card
        sx={{
          backgroundColor: 'rgba(255, 253, 240, 0.95)',
          borderRadius: '15px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
          overflow: 'hidden',
          maxWidth: '600px',
          width: '100%',
        }}
      >
          <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '2rem !important',
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontFamily: '"Yuji Boku", serif',
              fontWeight: 700,
              color: '#333',
              marginBottom: '1.5rem',
            }}
          >
            トレンド俳句を詠む
          </Typography>
          <Box sx={{ marginBottom: '1.5rem', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '0.5rem' }}>
            {trends.map((trend, index) => (
              <Chip
                key={index}
                label={trend}
                color={selectedTrend === trend ? "secondary" : "primary"}
                onClick={() => handleTrendClick(trend)}
                sx={{ fontFamily: '"Yuji Boku", serif' }}
              />
            ))}
          </Box>
          {haiku.map((line, index) => (
            <TextField
              key={index}
              value={line}
              onChange={(e) => handleHaikuChange(index, e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ marginBottom: '1rem', fontFamily: '"Yuji Boku", serif' }}
              placeholder={`${index + 1}行目 (${index === 1 ? '14' : '10'}文字まで)`}
              inputProps={{ maxLength: index === 1 ? 14 : 10 }}
            />
          ))}
          {error && (
            <Alert severity="error" sx={{ marginBottom: '1rem', fontFamily: '"Yuji Boku", serif' }}>
              {error}
            </Alert>
          )}
          <Box
            ref={haikuRef}
            sx={{
              width: '300px',
              height: '400px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '1rem',
              backgroundImage: 'url("/images/washi-texture.jpg")',
              backgroundSize: 'cover',
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'center',
              alignItems: 'flex-start',
              marginBottom: '1rem',
              position: 'relative',
            }}
          >
            {haiku.map((line, index) => (
              <Typography
                key={index}
                sx={{
                  fontFamily: '"Yuji Boku", serif',
                  fontWeight: 500,
                  color: '#333',
                  writingMode: 'vertical-rl',
                  textOrientation: 'upright',
                  fontSize: `${fontSize}px`,
                  lineHeight: 1.7,
                  height: '100%',
                  margin: '0 0.5rem',
                }}
              >
                {line.split('').map((char, i) => (
                  <span key={i}>{char}</span>
                ))}
              </Typography>
            ))}
            <Typography
              sx={{
                fontFamily: '"Yuji Boku", serif',
                color: '#666',
                fontSize: '12px',
                position: 'absolute',
                bottom: '0.5rem',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                textAlign: 'center',
              }}
            >
              #トレンド俳句
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Button
              variant="contained"
              onClick={saveAsImage}
              sx={{
                fontFamily: '"Yuji Boku", serif',
                backgroundColor: '#4a4a4a',
                color: '#fff',
                '&:hover': { backgroundColor: '#333' },
              }}
            >
              画像保存
            </Button>
            <Button
              variant="contained"
              onClick={shareHaiku}
              sx={{
                fontFamily: '"Yuji Boku", serif',
                backgroundColor: '#1DA1F2',
                color: '#fff',
                '&:hover': { backgroundColor: '#1991DA' },
              }}
            >
              投稿する
            </Button>
          </Box>
        </CardContent>
      </Card>
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 300, opacity: 0 }}
            transition={{ type: 'spring', stiffness: 100 }}
            style={{
              position: 'fixed',
              top: '20px',
              right: '20px',
              zIndex: 9999,
            }}
          >
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={!!error}
              autoHideDuration={10000}
              onClose={handleCloseError}
              message={error}
              ContentProps={{
                sx: {
                  backgroundColor: '#f44336',
                  color: '#fff',
                  fontFamily: '"Yuji Boku", serif',
                  fontSize: '16px',
                  padding: '12px 24px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Haiku;