import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './components/Home';
import Haiku from './components/Haiku';
import SocialCardComponent from './components/SocialCardComponent';

const theme = createTheme({
  palette: {
    background: {
      default: '#f0e6d2',
    },
  },
  typography: {
    fontFamily: "'Yuji Boku', serif",
  },
});

function App() {
  return (
    
    <ThemeProvider theme={theme}>
      <SocialCardComponent
        title="トレンド俳句|タイムリーな俳句を作ろう!"
        content="summary_large_image"
        description="いまのトレンドを季語として使い俳句を作ろう！作った俳句はX(旧Twitter)で共有しよう!"
        image="https://trendhaiku.pages.dev/icon.png"
        url="https://trendhaiku.pages.dev/"
      />
      <CssBaseline />
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Yuji+Boku&display=swap');
      </style>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/haiku" element={<Haiku />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;